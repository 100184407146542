@import "./mixin.scss";

.home {
  // *********************************Features Section*****************************
  .feature {
    @include Flex();
    height: 100vh;
    // padding: 0 5rem;
    background-color: #a0c3d2;
    gap: 0rem;
    font-weight: 500;

    .mainContent {
      width: 40%;
      display: inline-block;
      // width: 470px;
      h2 {
        width: 600px;
        font-weight: 500;
        font-size: 2.2rem;
        line-height: 3.5rem;
        z-index: 10;
        // letter-spacing: 2px;
        // position: absolute;
      }
      p {
        font-weight: 600;
        margin-top: 3rem;
        font-size: 1.5rem;
      }

      .smile {
        z-index: 1;
        position: relative;
        // position: absolute;
        left: 410px;
        top: 80px;
      }
    }
    .sachets {
      width: 40%;
      display: flex;
      transform: translate(-220px, 0);

      .sachet1 {
        position: relative;
        transform-origin: center;
        transform: translate(60%, -5%) rotate(-20deg);
        opacity: 1;
        z-index: 1;
      }
      .sachet2 {
        transform: translate(0, 20%);
        z-index: 3;
      }
      .sachet3 {
        position: relative;
        transform: translate(-60%, 0) rotate(20deg);
        z-index: 2;
      }
    }

    @media (max-width: 1441px) {
      .mainContent {
        width: 40%;
        transform: translate(-50px, 0);
        // margin: 0 10%;
        h2 {
          font-size: 1.8rem;
          line-height: 3rem;
        }
        p {
          margin-top: 1rem;
          font-size: 1.4rem;
        }
        .smile {
          left: 340px;
          top: 70px;
          width: 40px;
          transform: scale(1.2);
        }
      }
      .sachets {
        width: 40%;
        transform: translate(-200px, 0) scale(0.8);
      }
    }
    @media (max-width: 1201px) {
      .mainContent {
        width: 40%;
        transform: translate(-50px, 0);
        // margin: 0 10%;
        h2 {
          font-size: 1.6rem;
          line-height: 2.7rem;
        }
        p {
          margin-top: 1rem;
          font-size: 1.2rem;
        }
        .smile {
          left: 340px;
          top: 70px;
          width: 40px;
          transform: scale(1.2);
        }
      }
      .sachets {
        width: 40%;
        transform: translate(-240px, 0) scale(0.7);
      }
    }
    @media (max-width: 1024px) {
      // flex-direction: column;
      .mainContent {
        width: 40%;
        margin: 0 10%;
        h2 {
          font-size: 1.3rem;
          line-height: 2.5rem;
        }
        p {
          margin-top: 1rem;
          font-size: 1.2rem;
        }
        .smile {
          left: 260px;
          top: 60px;
          width: 40px;
          transform: scale(0.95);
        }
      }
      .sachets {
        width: 40%;
        transform: translate(-330px, 0) scale(0.6);
      }
    }
    @media (max-width: 850px) {
      // flex-direction: column;
      .mainContent {
        width: 40%;
        margin: 0 10%;
        h2 {
          font-size: 1.2rem;
          line-height: 2.3rem;
        }
        p {
          margin-top: 1rem;
          font-size: 1.2rem;
        }
        .smile {
          left: 230px;
          top: 60px;
          width: 40px;
          transform: scale(0.95);
        }
      }
      .sachets {
        width: 40%;
        transform: translate(-310px, 0) scale(0.55);
      }
    }

    @media (max-width: 768px) {
      // padding: 0 0.rem;
      justify-content: center;
      flex-direction: column-reverse;
      height: 100%;
      .mainContent {
        @include Flex();
        gap: 0;
        flex-direction: column;
        text-align: center;
        position: relative;
        transform: translateY(-40px);

        width: 100%;
        h2 {
          margin: 0;
          width: 100%;
          font-size: 1.2rem;
          line-height: 2rem;
        }
        p {
          margin-top: 0.5rem;
          font-size: 1rem;
        }
        .smile {
          left: 130px;
          top: 30px;
          width: 30px;
          // transform: scale(0.7);
        }
      }
      .sachets {
        width: 100vw;
        transform: translate(-15%, 0) scale(0.5);
        margin: 0;
      }
    }
    @media (max-width: 616px) {
      .sachets {
        transform: translate(-20%, 0) scale(0.5);
      }
    }
    @media (max-width: 550px) {
      .sachets {
        transform: translate(-26%, 0) scale(0.5);
      }
    }
    @media (max-width: 500px) {
      .sachets {
        transform: translate(-32%, 0) scale(0.5);
      }
    }
    @media (max-width: 450px) {
      .sachets {
        transform: translate(-38%, 0) scale(0.5);
      }
    }
    @media (max-width: 400px) {
      .sachets {
        transform: translate(-45%, 0) scale(0.5);
      }
    }
    @media (max-width: 380px) {
      .sachets {
        transform: translate(-45%, 0) scale(0.45);
      }
    }
    @media (max-width: 350px) {
      .sachets {
        transform: translate(-52%, 0) scale(0.42);
      }
    }
  }

  // *********************************Quote Section*****************************
  .QuoteSection {
    width: 100vw;
    // height: 100vh;
    background-color: #fef4eb;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 10rem 0;

    .quoteLeft {
      width: 40%;
      height: 600px;
      // background-color: #248277;
      overflow: hidden;

      .quoteElipseU {
        position: absolute;
        transform: translate(-65px, -88px);
        height: 238px;
      }
      .quoteLeftImgContainer {
        @include Flex();
        width: 620px;
        height: 600px;
        overflow: hidden;
        img {
          height: 100%;
        }
      }
    }
    .quoteRight {
      position: relative;
      transform: translate(-40px, 0);
      width: 600px;
      height: 350px;
      background-color: #e2d4d3;
      color: #3e3e3e;
      z-index: 10;
      margin-top: 5rem;
      box-shadow: 0px 25px 50px rgba(0, 0, 0, 0.13);

      .quotLeftContent {
        height: 90%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        gap: 2rem;
        padding-left: 3rem;
        padding-top: 2rem;

        h2 {
          margin: 0;
          // margin: 5px 0 0 0;
          font-size: 3rem;
          font-weight: 500;
        }
        .lowerHeading {
          margin-left: 15px;
        }
      }

      .quoteLeftrequestBtn {
        border: none;
        background-color: #ffe8d3;
        font-size: 1.3rem;
        padding: 12px 16px;
        width: 180px;
        margin-left: 5px;
        border-radius: 10px;

        &:hover {
          background-color: #ffdfc1;
        }
      }

      .quoteElipseL {
        position: absolute;
        z-index: 1;
        transform: translate(500px, -65px);
        height: 200px;
      }
    }

    @media (max-width: 1024px) {
      // height: 100vh;
      padding: 6rem 0;
      .quoteLeft {
        height: 400px;
        .quoteLeftImgContainer {
          height: 400px;
          width: 400px;
        }

        .quoteElipseU {
          height: 180px;
          transform: translate(-48px, -66px);
        }
      }
      .quoteRight {
        height: 300px;
        width: 400px;
        transform: translate(-40px, -10%);
        display: flex;
        flex-direction: column;

        .quotLeftContent {
          margin: 2rem;
          padding-left: 0rem;
          h2 {
            font-size: 2.2rem;
          }
          .lowerHeading {
            margin-left: 15px;
          }

          .quoteLeftrequestBtn {
            font-size: 1.2rem;
            padding: 12px 10px;
            margin-left: 0;
            border-radius: 10px;

            &:hover {
              background-color: #ffdfc1;
            }
          }
        }

        .quoteElipseL {
          height: 150px;
          transform: translate(325px, 250px);
        }
      }
    }
    @media (max-width: 768px) {
      // height: 100vh;
      // padding: 6rem 0;
      .quoteLeft {
        height: 350px;
        .quoteLeftImgContainer {
          height: 350px;
          width: 350px;
        }
      }
      .quoteRight {
        height: 230px;
        width: 300px;
        transform: translate(-30px, -25px);

        .quotLeftContent {
          margin: 2rem;
          padding-left: 0rem;
          padding-top: 0rem;
          gap: 1rem;

          h2 {
            font-size: 1.5rem;
            line-height: 2.2rem;
            margin: 0;
          }
          .lowerHeading {
            margin: 0;
            margin-left: 15px;
          }
          .quoteLeftrequestBtn {
            font-size: 1rem;
            border-radius: 7px;
            width: 130px;
            &:hover {
              background-color: #ffdfc1;
            }
          }
        }

        .quoteElipseL {
          height: 150px;
          transform: translate(225px, 200px);
        }
      }
    }
    @media (max-width: 527px) {
      padding: 3rem 0 1rem 0;
      flex-direction: column;
      align-items: center;
      .quoteLeft {
        @include Flex();
        // height: 200px;
        width: 100vw;
        .quoteLeftImgContainer {
          height: 75vw;
          width: 80vw;
          img {
            width: 100%;
            height: auto;
          }
        }
        .quoteElipseU {
          display: none;
        }
      }
      .quoteRight {
        height: 200px;
        width: 70vw;
        margin: 0;
        transform: translate(0, -50px);
        justify-content: center;
        align-items: center;

        .quotLeftContent {
          @include Flex();
          gap: 1rem;

          h2 {
            text-align: center;
            font-size: 1.2rem;
            line-height: 2rem;
            margin: 0;
          }
        }

        .quoteElipseL {
          display: none;
        }
      }
    }
    @media (max-width: 375px) {
      padding: 1rem 0 0 0;
      .quoteRight {
        transform: translate(0, -80px);
      }
    }
  }

  // *********************************Achievement Section*****************************
  .achievContainer {
    padding: 4rem 0;
    h1 {
      text-align: center;
      font-size: 2.3rem;
      font-weight: 600;
      line-height: 160%;
      // margin: 0;
    }
    .achievinfoContent {
      width: 80%;
      text-align: center;
      margin: 1rem auto 3rem auto;
      // font-size: 1.6rem;
      font-weight: 500;
    }

    .achiev {
      background-color: white;
      // height: 300px;
      margin: 2rem 0 1rem 0;
      @include Flex();
      gap: 1rem;

      h2 {
        margin: 0;
        margin-top: 10px;
        font-size: 3rem;
        font-weight: 600;
      }
      p {
        margin: 0;
        text-align: center;
        font-size: 1.5rem;
        font-weight: 300;
      }
      .capsule {
        @include Flex();
        flex-direction: column;
        gap: 0rem;
        width: 30%;
        height: 300px;
        .imgComponent {
          width: 150px;
          height: 150px;
        }
      }
    }

    @media (max-width: 1024px) {
      .achiev {
        // height: 295px;
        h2 {
          font-size: 2rem;
        }
        p {
          font-size: 1.5rem;
        }
        .capsule {
          .imgComponent {
            width: 120px;
            height: 120px;
          }
        }
      }
    }
    @media (max-width: 900px) {
      h1 {
        font-size: 2rem;
      }
    }
    // @media (max-width: 577px) {
    //   padding: 1rem 0 2rem 0;

    //   h1 {
    //     font-size: 2.5rem;
    //   }
    //   .achiev {
    //     // height: 250px;
    //     h2 {
    //       // margin: 0;
    //       font-size: 1.8rem;
    //     }
    //     p {
    //       font-size: 1.2rem;
    //     }
    //     .capsule {
    //       height: 250px;
    //       .imgComponent {
    //         width: 100px;
    //         height: 100px;
    //       }
    //     }
    //   }
    // }
    @media (max-width: 600px) {
      padding: 1rem 0 2rem 0;
      h1 {
        font-size: 1.5rem;
      }
      .achievinfoContent {
        width: 95%;
        margin-bottom: 2rem;
      }
      .achiev {
        flex-direction: column;
        // height: 250px;
        h2 {
          // margin: 0;
          font-size: 2rem;
        }
        p {
          font-size: 1.5rem;
        }
        .capsule {
          height: 250px;
          .imgComponent {
            width: 100px;
            height: 100px;
          }
        }
      }
    }
  }

  // *********************************Benefits Section*****************************

  .benefitsSection {
    background-color: #ffe1e1;
    // padding: 3rem 0;
    margin: 0;

    .upperElipse {
      border-radius: 80% 80% 0 0;
      background-color: #ffe1e1;
      transform: translate(0, -30px);
      height: 50px;
    }
    .lowerElipse {
      border-radius: 0 0 80% 80%;
      background-color: #ffe1e1;
      transform: translate(0, 25px);
      height: 50px;
    }

    .benefitsHeadingContent {
      margin: 1rem 6rem 6rem 6rem;

      h3 {
        font-size: 3rem;
        font-weight: 700;
        margin: 0;
      }
      p {
        font-size: 1.5rem;
        font-weight: 500;
        margin: 0;
      }
    }

    .cardContainer {
      display: flex;
      flex-direction: column;
      gap: 3rem;
      text-decoration: none;

      h3 {
        width: 90%;
        font-size: 1.3rem;
        margin: 1rem 0 0 0;
        // margin-left: 1rem;
      }
      p {
        width: 90%;
        margin-top: 5px;
        // margin-left: 1rem;
        line-height: 160%;
        font-size: 1.08rem;
        font-weight: 400;
      }
      .benefitCardModule {
        background-color: white;
        width: 350px;
        height: 150px;
        padding: 2rem 0rem 2rem 2rem;
        border-radius: 0 0 30px 30px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
          rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
        text-decoration: none;
      }
      .upperPart {
        @include Flex();
        margin: 0 auto;
        width: 100%;
        gap: 5rem;
      }
      .lowerPart {
        @include Flex();
        margin: 0 auto;
        width: 100%;
        gap: 5rem;

        .leftElement {
          height: 180px;
          position: absolute;
          left: 50px;
        }
        .rightElement {
          height: 180px;
          position: absolute;
          right: 50px;
          transform: Scalex(-1);
        }
      }

      .leftUpperCard {
        position: relative;
        left: 90px;
      }
      .leftLowerCard {
        position: relative;
        left: 90px;
      }
    }

    .quoteElipseU {
      width: 100vw;
      transform: translate(0, 100%);
    }

    @media (max-width: 1440px) {
      .cardContainer {
        .upperPart {
          gap: 2rem;
        }
        .lowerPart {
          gap: 2rem;
          .leftElement {
            height: 130px;
            left: 3%;
          }
          .rightElement {
            height: 130px;
            right: 3%;
          }
        }
      }
    }
    @media (max-width: 1207px) {
      .benefitsHeadingContent {
        margin: 0 4rem 3rem 4rem;
        h3 {
          font-size: 2.5rem;
        }
        p {
          font-size: 1.2rem;
        }
      }
      .cardContainer {
        width: 100vw;
        flex-direction: column-reverse;
        gap: 2rem;
        .upperPart {
          gap: 2rem;
          width: 100%;
          flex-wrap: wrap;
          // row-gap: 3rem;
        }
        .lowerPart {
          gap: 2rem;
          flex-wrap: wrap;
          width: 100%;
          .leftElement {
            display: none;
          }
          .rightElement {
            display: none;
          }
        }
      }
    }

    @media (max-width: 720px) {
      padding-bottom: 4%;
      .quoteElipseU {
        transform: translate(0, 180%);
      }
    }
    @media (max-width: 450px) {
      .benefitsHeadingContent {
        margin: 0 1.5rem 2rem 1.5rem;
        h3 {
          font-size: 1.8rem;
        }
        p {
          font-size: 1.2rem;
        }
      }
      .cardContainer {
        .upperPart {
          width: 100%;
        }
        .lowerPart {
          width: 100%;
        }
        .benefitCardModule {
          width: 310px;
          margin: auto;
          height: 150px;
        }
      }
    }
  }
}

// *********************************Testimonial Section*****************************
.testimonial {
  padding: 9rem 0 0 0;
  h2 {
    margin: 0;
    font-size: 3rem;
    font-weight: 500;
    text-align: center;
  }
  .videoContainer {
    .videoBox {
      height: 600px;
      width: 70%;
      margin: 4rem auto;
      border-radius: 15px;
      background-color: #b5b5b5;
      overflow: hidden;
    }
    .videoListContainer {
      width: 80%;
      margin: 0rem auto;
      display: flex;
      justify-content: center;
      gap: 2rem;
      .videoItem {
        .videoThumbnail {
          @include Flex();
          background-color: #b5b5b5;
          border-radius: 10px;
          height: 200px;
          width: 300px;
          overflow: hidden;
        }
        h3 {
          color: #333333;
          width: 300px;
          font-weight: 800;
          margin: 10px 0 6px 0;
        }
        p {
          margin: 0;
          width: 300px;
          color: #666666;
        }
      }
    }
  }

  .testimonialLowerElipse {
    width: 100vw;
    transform: translate(0, 105%);
  }
  @media (max-width: 2560px) {
    .videoContainer {
      .videoBox {
        height: 900px;
        width: 60%;
        margin-top: 2.5rem;
      }
    }
  }
  @media (max-width: 2120px) {
    .videoContainer {
      .videoBox {
        height: 800px;
        width: 60%;
        margin-top: 2.5rem;
      }
    }
  }
  @media (max-width: 1850px) {
    .videoContainer {
      .videoBox {
        height: 700px;
        width: 60%;
        margin-top: 2.5rem;
      }
    }
  }
  @media (max-width: 1650px) {
    .videoContainer {
      .videoBox {
        height: 700px;
        width: 75%;
        margin-top: 2.5rem;
      }
    }
  }
  @media (max-width: 1024px) {
    padding: 6rem 0 0 0;
    .videoContainer {
      .videoBox {
        height: 500px;
        width: 90%;
        margin-top: 2.5rem;
      }
      .videoListContainer {
        width: 90%;
        margin: 0rem auto;
        gap: 2rem;
        .videoItem {
          .videoThumbnail {
            height: 150px;
            width: 250px;
          }
          h3 {
            width: 250px;
            margin: 10px 0 6px 0;
          }
          p {
            width: 250px;
          }
        }
      }
    }
  }
  @media (max-width: 830px) {
    padding: 4rem 0 0 0;
    h2 {
      font-size: 2.5rem;
    }
    .videoContainer {
      .videoBox {
        margin: 2rem auto;
        width: 90%;
        height: 500px;
      }
      .videoListContainer {
        width: 90%;
        margin: 0rem auto;
        gap: 1rem;
        .videoItem {
          .videoThumbnail {
            height: 130px;
            width: 230px;
          }
          h3 {
            width: 230px;
            font-size: 1.1rem;
          }
          p {
            width: 230px;
            font-size: 0.9rem;
          }
        }
      }
    }
  }
  @media (max-width: 720px) {
    padding: 4rem 0 0 0;
    h2 {
      font-size: 2rem;
    }
    .videoContainer {
      .videoBox {
        width: 90%;
        height: 400px;
      }
      .videoListContainer {
        gap: 0.7rem;
        .videoBox {
          width: 90%;
          height: 400px;
        }
        .videoItem {
          .videoThumbnail {
            height: 120px;
            width: 210px;
          }
          h3 {
            width: 210px;
            font-size: 1rem;
          }
          p {
            width: 210px;
            font-size: 0.9rem;
          }
        }
      }
    }
  }
  @media (max-width: 590px) {
    .videoContainer {
      .videoBox {
        width: 90%;
        height: 300px;
      }
      .videoListContainer {
        gap: 0.7rem;
        .videoItem {
          .videoThumbnail {
            height: 100px;
            width: 160px;
          }
          h3 {
            width: 160px;
            font-size: 0.9rem;
          }
          p {
            width: 160px;
            font-size: 0.9rem;
          }
        }
      }
    }
  }
  @media (max-width: 500px) {
    .videoContainer {
      .videoListContainer {
        flex-direction: column;
        align-items: center;
        gap: 0.7rem;
        .videoItem {
          @include Flex();
          gap: 1rem;
        }
      }
    }
  }
  @media (max-width: 430px) {
    padding: 3rem 0 0 0;
    h2 {
      font-size: 1.7rem;
    }
    .videoContainer {
      .videoBox {
        border-radius: 10px;
        width: 95%;
        height: 220px;
        margin: 1.5rem auto;
      }
    }
  }
}

// *********************************Blog Section*****************************

.blogSection {
  padding: 8rem 8%;
  background-color: #e3e9f9;
  h1 {
    font-size: 3rem;
    text-align: center;
    margin-bottom: 5rem;
  }
  .blogLink {
    display: block;
    width: 98%;
    margin: 10px 0;
    text-align: right;
    // margin-right: 10px;
    font-weight: 600;
    text-decoration: none;
    color: black;
    cursor: pointer;
  }
  .blogContainer {
    display: flex;
    gap: 5%;
    justify-content: center;
    @media (max-width: 1440px) {
      justify-content: center;
      gap: 3%;
    }
    @media (max-width: 770px) {
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 3rem;
    }
  }
  @media (max-width: 1440px) {
    padding: 8rem 6%;
  }
  @media (max-width: 1024px) {
    .blogContainer {
      gap: 1rem;
    }
  }
  @media (max-width: 768px) {
    padding: 4rem 3%;
    h1 {
      font-size: 2.5rem;
      margin-bottom: 0;
    }
    .blogLink {
      font-size: 1rem;
      margin-top: 20px;
      margin-bottom: 5px;
    }
  }
  @media (max-width: 500px) {
    padding: 2rem 8%;
    .blogContainer {
      flex-direction: column;
      gap: 1.5rem;
      align-items: center;
    }
  }
}

// *********************************Pharmacy all you need Section*****************************
.joinToday {
  text-align: center;
  background-color: #ffe3ce;
  padding: 6rem 3rem;
  h1 {
    width: 80%;
    font-size: 2.5rem;
    margin: 1rem auto;
    text-align: center;
    line-height: 150%;
  }
  p {
    font-size: 1.5rem;
    font-weight: 400;
    margin: 1.2rem 0;
  }
  .joinTodaybtn {
    margin-top: 1rem;
    border-radius: 40px;
    border: none;
    height: 60px;
    width: 200px;
    color: white;
    font-size: 1.1rem;
    background-color: #6d5348;
    cursor: pointer;
    font-weight: 500;

    &:hover {
      background-color: #5f3f31;
    }
  }
  @media (max-width: 1024px) {
    padding: 4rem 3rem;
    h1 {
      font-size: 2rem;
    }
  }
  @media (max-width: 768px) {
    padding: 3rem 3rem;
    h1 {
      width: 100%;
      font-size: 2rem;
      margin: 0 auto;
    }
    p {
      margin: 10px 0;
      font-size: 1.3rem;
    }
    .joinTodaybtn {
      height: 50px;
      width: 160px;
    }
  }
  @media (max-width: 550px) {
    padding: 3rem 1rem;
    h1 {
      font-size: 1.5rem;
    }
    p {
      margin: 12px 0;
      font-size: 1rem;
    }
    .joinTodaybtn {
      margin-top: 0;
      height: 45px;
      width: 140px;
    }
  }
  @media (max-width: 440px) {
    padding: 3rem 1rem;
    h1 {
      font-size: 1.3rem;
    }
    p {
      margin: 12px 0;
      font-size: 1rem;
    }
    .joinTodaybtn {
      margin-top: 0;
      height: 40px;
      width: 120px;
      font-size: 1rem;
    }
  }
}
